import { default as billingyLDhzZ3xzxMeta } from "/opt/buildhome/repo/pages/account/billing.vue?macro=true";
import { default as companyXVmC8nnKn1Meta } from "/opt/buildhome/repo/pages/account/company.vue?macro=true";
import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as accountFCGaMrzILUMeta } from "/opt/buildhome/repo/pages/account.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as _91id_93VJKvy89fopMeta } from "/opt/buildhome/repo/pages/monitors/[id].vue?macro=true";
import { default as notifications3rfhjEqjJjMeta } from "/opt/buildhome/repo/pages/notifications.vue?macro=true";
import { default as onboardingYOkwTJhfSrMeta } from "/opt/buildhome/repo/pages/onboarding.vue?macro=true";
import { default as registerynsVf0yvMzMeta } from "/opt/buildhome/repo/pages/register.vue?macro=true";
import { default as teamQ6SxuPfnNIMeta } from "/opt/buildhome/repo/pages/team.vue?macro=true";
export default [
  {
    name: accountFCGaMrzILUMeta?.name,
    path: "/account",
    meta: accountFCGaMrzILUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account.vue"),
    children: [
  {
    name: "account-billing",
    path: "billing",
    meta: billingyLDhzZ3xzxMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/billing.vue")
  },
  {
    name: "account-company",
    path: "company",
    meta: companyXVmC8nnKn1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/account/company.vue")
  },
  {
    name: "account",
    path: "",
    meta: indexy4DdxYIhQyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "monitors-id",
    path: "/monitors/:id()",
    meta: _91id_93VJKvy89fopMeta || {},
    component: () => import("/opt/buildhome/repo/pages/monitors/[id].vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notifications3rfhjEqjJjMeta || {},
    component: () => import("/opt/buildhome/repo/pages/notifications.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingYOkwTJhfSrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/onboarding.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerynsVf0yvMzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/register.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamQ6SxuPfnNIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/team.vue")
  }
]