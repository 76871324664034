export default defineNuxtRouteMiddleware((to) => {
  const { isAuthenticated } = useSanctumAuth()

  if (isAuthenticated.value) {
    const user = useAuthenticatedUser()

    if (!user.value.is_onboarded && to.path !== '/onboarding') {
      return navigateTo('/onboarding')
    }
  }
})
