import type { FetchContext } from 'ofetch'
import { stringify } from 'qs'
import type { NuxtApp } from '#app'

export default defineAppConfig({
  sanctum: {
    interceptors: {
      onRequest: async (_app: NuxtApp, { options }: FetchContext) => {
        if (options.query && typeof options.query === 'object') {
          options.query = Object.fromEntries(new URLSearchParams(stringify(options.query)))
        }
      },
    },
  },
})
