<template>
  <UiAlert
    title="Details updated succesfully"
    content="We have successfully updated your company details"
    @close="$emit('close')"
  />
</template>

<script lang="ts" setup>
defineProps<{
  title: string
  content: string
}>()

const emit = defineEmits<{
  close: []
}>()

onMounted(() => {
  setTimeout(() => emit('close'), 5000)
})
</script>
