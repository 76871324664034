export interface ConfirmDialog {
  title: string
  content: string
  cancelButtonText: string
  confirmButtonText: string
  onClose: (confirmed: boolean) => void
}

const active = ref<ConfirmDialog | null>(null)
const isOpen = ref(false)

export default function () {
  const show = (confirmDialog: ConfirmDialog) => {
    active.value = confirmDialog
    isOpen.value = true
  }

  const close = (confirmed: boolean) => {
    isOpen.value = false
    active.value?.onClose(confirmed)
  }

  return { active, isOpen, show, close }
}
