<template>
  <div class="h-full">
    <NuxtRouteAnnouncer />
    <NuxtLoadingIndicator
      :color="colors.brand[600]"
      :error-color="colors.error[600]"
    />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <UiToastContainer />
    <UiDialogConfirm
      v-if="activeDialog"
      :is-open="isDialogOpen"
      v-bind="activeDialog"
      @close="closeDialog($event)"
    />
  </div>
</template>

<script setup>
import colors from '#tailwind-config/theme/colors'

// Use SSR-safe IDs for Headless UI
provideHeadlessUseId(() => useId())

const { active: activeDialog, isOpen: isDialogOpen, close: closeDialog } = useConfirmDialog()
</script>
