<template>
  <div class="fixed bottom-4 right-4 z-toasts flex w-90 flex-col gap-md">
    <TransitionGroup
      enter-active-class="transition ease-out duration-200"
      enter-from-class="transform translate-x-full scale-95 opacity-0"
      enter-to-class="transform translate-x-0 scale-100 opacity-100"
      leave-active-class="transition ease-in duration-200"
      leave-from-class="transform translate-x-0 scale-100 opacity-100"
      leave-to-class="transform translate-x-full scale-95 opacity-0"
    >
      <UiToast
        v-for="toast in toasts"
        :key="toast.id"
        :title="toast.title"
        :content="toast.content"
        @close="remove(toast.id)"
      />
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
const { toasts, remove } = useToast()
</script>
