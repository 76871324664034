interface Toast {
  title: string
  content: string
}

interface QueuedToast extends Toast {
  id: number
}

let counter = 0
const toasts = ref<QueuedToast[]>([])

export default function () {
  const show = (toast: Toast) => {
    toasts.value.push({
      id: counter++,
      ...toast,
    })
  }

  const remove = (id: number) => {
    toasts.value = toasts.value.filter(toast => toast.id !== id)
  }

  return {
    toasts,
    show,
    remove,
  }
}
