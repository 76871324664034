<template>
  <span v-html="svgContent" />
</template>

<script lang="ts" setup>
const props = defineProps<{
  name: string
}>()

const icons = import.meta.glob('~/assets/icons/*.svg', {
  eager: true,
  query: '?raw',
  import: 'default',
})

// Construct a dictionary to optimize lookups by the icon name
const iconLookups: Record<string, string> = {}

Object.keys(icons).forEach((iconPath) => {
  // We are assuming that all icons used in the project are following the convention
  const [, iconName] = iconPath.match(/\/([a-z0-9-]+)\.svg$/)!

  iconLookups[iconName] = iconPath
})

const svgContent = computed(() => {
  const iconPath = iconLookups[props.name]

  if (!iconPath) {
    throw new Error(`Icon not found: ${props.name}`)
  }

  return icons[iconPath]
})
</script>
